<template>
  <v-dialog v-model="value" class="ag-dialog" :width="width" :persistent="persistent">
    <v-card class="ag-dialog__card">
      <v-card-title v-if="title" class="ag-dialog__card-title">
        {{ title }}
        <v-spacer />
        <v-btn v-if="showClose" id="btnCloseX" icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <slot v-else-if="$slots.header" name="header" class="ag-dialog__card-title" />

      <v-divider class="ma-0" />

      <v-card-text class="ag-dialog__card-body" :class="{ 'ag-dialog__card-body--no-padding': noPadding }">
        <slot />
      </v-card-text>

      <slot v-if="$slots.footer" name="footer" />
      <v-card-actions v-else class="ag-dialog__card-footer">
        <v-btn
          v-if="hideSecondaryButton && spaceBetweenButtons"
          class="btn-ag-secondary"
          @click="$emit('secondaryButtonClick')"
        >
          {{ secondaryButtonText }}
        </v-btn>
        <v-spacer />
        <v-btn v-if="!hideTertiaryButton" class="btn-ag-tertiary" @click="$emit('tertiaryButtonClick')">
          {{ tertiaryButtonText }}
        </v-btn>
        <v-btn
          v-if="!hideSecondaryButton && !spaceBetweenButtons"
          class="btn-ag-secondary"
          @click="$emit('secondaryButtonClick')"
        >
          {{ secondaryButtonText }}
        </v-btn>
        <v-btn v-if="!hidePrimaryButton" class="btn-ag-primary" @click="$emit('primaryButtonClick')">
          {{ primaryButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AGDialog',
  props: {
    value: {
      type: Boolean,
      default: false,
      required: true,
    },

    title: {
      type: String,
      default: '',
    },

    showClose: {
      type: Boolean,
      default: true,
    },

    noPadding: {
      type: Boolean,
      default: false,
    },

    width: {
      type: String,
      default: '500px',
    },

    primaryButtonText: {
      type: String,
      default: 'Salvar',
    },

    secondaryButtonText: {
      type: String,
      default: 'Cancelar',
    },

    tertiaryButtonText: {
      type: String,
      default: 'Cancelar',
    },

    hidePrimaryButton: {
      type: Boolean,
      default: false,
    },

    hideSecondaryButton: {
      type: Boolean,
      default: true,
    },

    hideTertiaryButton: {
      type: Boolean,
      default: false,
    },

    spaceBetweenButtons: {
      type: Boolean,
      default: false,
    },

    persistent: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    close() {
      this.value = false;
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
.ag-dialog__card {
  padding: 0 !important;
  .ag-dialog__card-title {
    padding: 16px;
  }
  .ag-dialog__card-body {
    padding: 16px;
    &--no-padding {
      padding: 16px 0 !important;
    }
  }
  .ag-dialog__card-footer {
    padding: 16px;
  }
}
</style>
