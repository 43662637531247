var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      staticClass: "ag-dialog",
      attrs: { width: _vm.width, persistent: _vm.persistent },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "ag-dialog__card" },
        [
          _vm.title
            ? _c(
                "v-card-title",
                { staticClass: "ag-dialog__card-title" },
                [
                  _vm._v(" " + _vm._s(_vm.title) + " "),
                  _c("v-spacer"),
                  _vm.showClose
                    ? _c(
                        "v-btn",
                        {
                          attrs: { id: "btnCloseX", icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    : _vm._e(),
                ],
                1
              )
            : _vm.$slots.header
            ? _vm._t("header")
            : _vm._e(),
          _c("v-divider", { staticClass: "ma-0" }),
          _c(
            "v-card-text",
            {
              staticClass: "ag-dialog__card-body",
              class: { "ag-dialog__card-body--no-padding": _vm.noPadding },
            },
            [_vm._t("default")],
            2
          ),
          _vm.$slots.footer
            ? _vm._t("footer")
            : _c(
                "v-card-actions",
                { staticClass: "ag-dialog__card-footer" },
                [
                  _vm.hideSecondaryButton && _vm.spaceBetweenButtons
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-ag-secondary",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("secondaryButtonClick")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.secondaryButtonText) + " ")]
                      )
                    : _vm._e(),
                  _c("v-spacer"),
                  !_vm.hideTertiaryButton
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-ag-tertiary",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("tertiaryButtonClick")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.tertiaryButtonText) + " ")]
                      )
                    : _vm._e(),
                  !_vm.hideSecondaryButton && !_vm.spaceBetweenButtons
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-ag-secondary",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("secondaryButtonClick")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.secondaryButtonText) + " ")]
                      )
                    : _vm._e(),
                  !_vm.hidePrimaryButton
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "btn-ag-primary",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("primaryButtonClick")
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(_vm.primaryButtonText) + " ")]
                      )
                    : _vm._e(),
                ],
                1
              ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }